*,
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica, sans-serif;
}

*:before,
*:after {
    box-sizing: inherit;
}

h1 {
    font-weight: normal;
}

a {
    text-decoration: none;
}

button {
    font-family: Helvetica, sans-serif;
}

::selection {
    background: #e50914;
    color: #fff;
}

input,
select,
textarea {
    color: #000;
}

textarea:focus,
input:focus {
    color: #000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* :focus {
    outline: 2px dashed white;
    border-radius: 3px;
    outline-offset: 2px;
} */